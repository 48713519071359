
import {ref} from 'vue'
import {useRouter} from "vue-router";
import axios from "axios";
import { message } from 'ant-design-vue'
import Cookies from 'js-cookie'

export default {
  name: 'login',
  setup() {
    let form = ref({
      username: null,
      password: null
    })

    let formLog = ref({
      username: null,
      password: null,
      passwordConfirm: null
    })

    let ifLogin = ref(true)

    const router = useRouter()

    function onSubmit() {
      axios.post('http://158.101.11.26:8000/api/test_login_info', form.value).then(res => {
        console.log(res)
        if (res.data.result == 1) {
          message.success(
            '登陆成功，2s后跳转至组件介绍页面',
            2,
              (()=>{
                Cookies.set('username', form.value.username)
                router.push({
                  path: "/Introduction"
                })
              })
          );
        } else {
          message.error(
            '用户名或密码错误，请重新输入',
            2,
          );
        }
      })
    }

    function changeLog(){
      ifLogin.value = false
    }

    function onLog(){
      console.log(formLog.value)
      axios.post('http://158.101.11.26:8000/api/log', formLog.value).then(res => {
        console.log(res)
        if (res.data.result == 1) {
          message.success(
            '注册成功，2s后跳转至登陆页面',
            2,
              (()=>{
                ifLogin.value = true
              })
          );
        } else {
          message.error(
            '用户名已存在，请重新输入',
            2,
          );
        }
      })

    }

    return {
      form,
      formLog,
      ifLogin,
      onSubmit,
      changeLog,
      onLog,
    }
  }
}
