
import {ref, defineComponent, Ref, reactive} from "vue";
import axios from "axios";

interface User {
  key: number
  dataName: string
  dataId: number
}


export default defineComponent({
  name: "testModal",
  data() {
    return {
      selectedRows: [],
      selectedRowKeys: [],
      selectedRowName: null,
      data: reactive({
        dataBase: [],
        dataColumns: []
      }),
      showData: false,
    }
  },
  setup() {
    let visible = ref(false)
    const showModal = () => {
      visible.value = true
    }
    const handleOk = () => {
      visible.value = false
    }

    const tableData: User[] = [
      {
        key: 1,
        dataName: '鸢尾花数据集',
        dataId: 2
      },
      {
        key: 2,
        dataName: '信贷数据集',
        dataId: 3,
      },
    ]
    const columns = [
      {
        title: 'dataName',
        dataIndex: 'dataName',
      }
    ];

    return {
      visible,
      showModal,
      handleOk,
      tableData,
      columns
    }
  },
  methods: {
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectionRows
      this.selectedRowName = selectionRows[0].dataName
      axios.get('http://158.101.11.26:8000/api/' + 'show_yuanweihua').then(res => {
        console.log(res)
        if (res.statusText === 'OK') {
          const data = []
          res.data.list.forEach((item) => {
            data.push(item.fields)
          })
          this.data.dataBase = data
          this.data.dataColumns = Object.keys(data[0])
          this.showData = true
        }
      })
    },
  }
})
