
import {defineComponent} from 'vue';
import HelloWorld from './components/HelloWorld.vue';
import homeView from "@/components/homeView.vue";
import testNavi from "@/components/testNavi.vue";

export default defineComponent({
  name: 'App',
  components: {
    // HelloWorld
    // homeView,
    testNavi
  }
});
