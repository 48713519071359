import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b943c2b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_laptop_outlined = _resolveComponent("laptop-outlined")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_user_outlined = _resolveComponent("user-outlined")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_header, {
        class: "header",
        style: {"padding":"0 0"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { span: 4 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_laptop_outlined, { style: {"fontSize":"25px","color":"white"} }),
                    _createVNode(_component_el_text, { style: {"color":"white","font-size":"25px","margin-left":"10px"} }, {
                      default: _withCtx(() => [
                        _createTextVNode("Data Mining")
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 20 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu, {
                    selectedKeys: _ctx.selectedKeys1,
                    "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeys1) = $event)),
                    theme: "dark",
                    mode: "horizontal",
                    style: { lineHeight: '64px'}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_menu_item, { key: "1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, { to: "/Introduction" }),
                          _createTextVNode(" 组件介绍 ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, { key: "2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, { to: "/homeView" }),
                          _createTextVNode(" 数据实验楼 ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_sub_menu, {
                        title: _ctx.showUserName,
                        style: {marginLeft: 'auto'}
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_user_outlined)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_menu_item, { onClick: _ctx.exit }, {
                            default: _withCtx(() => [
                              _createTextVNode("退出登录")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }, 8, ["title"])
                    ]),
                    _: 1
                  }, 8, ["selectedKeys"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}