import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ca0c00f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "label" }
const _hoisted_3 = {
  key: 0,
  class: "status"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "status"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "label" }
const _hoisted_9 = {
  key: 0,
  class: "status"
}
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (['success', 'failed'].includes($data.status))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["node", $data.status]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
        onContextmenu: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.rightClickFinish && $options.rightClickFinish(...args)))
      }, [
        _createElementVNode("img", {
          src: $data.image.logo
        }, null, 8, _hoisted_1),
        _createElementVNode("span", _hoisted_2, _toDisplayString($data.label), 1),
        ($data.status === 'success')
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createElementVNode("img", {
                src: $data.image.success
              }, null, 8, _hoisted_4)
            ]))
          : ($data.status === 'failed')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _createElementVNode("img", {
                  src: $data.image.failed
                }, null, 8, _hoisted_6)
              ]))
            : _createCommentVNode("", true)
      ], 34))
    : (['running', 'default'].includes($data.status))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["node", $data.status]),
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
          onContextmenu: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.rightClick && $options.rightClick(...args)))
        }, [
          _createElementVNode("img", {
            src: $data.image.logo
          }, null, 8, _hoisted_7),
          _createElementVNode("span", _hoisted_8, _toDisplayString($data.label), 1),
          ($data.status === 'running')
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                _createElementVNode("img", {
                  src: $data.image.running
                }, null, 8, _hoisted_10)
              ]))
            : _createCommentVNode("", true)
        ], 34))
      : _createCommentVNode("", true)
}