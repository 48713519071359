import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_test_navi = _resolveComponent("test-navi")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.meta.keepAlive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_test_navi),
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_KeepAlive, null, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ], 1024))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.$route.meta.keepAlive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}