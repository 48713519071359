
import {UserOutlined, LaptopOutlined, NotificationOutlined} from '@ant-design/icons-vue';
import {defineComponent, ref} from 'vue';
import {useRouter} from 'vue-router'
import Cookies from "js-cookie";
import {message} from "ant-design-vue";

export default defineComponent({
  name: 'testNavi',
  components: {
    UserOutlined,
    LaptopOutlined,
    NotificationOutlined,
  },
  setup() {
    let router = useRouter()
    return {
      selectedKeys1: ref<string[]>([router.currentRoute.value.fullPath === '/Introduction' ? '1' : '2']),
      collapsed: ref<boolean>(false),
    };
  },
  computed: {
    showUserName() {
      return Cookies.get('username')
    }
  },
  methods: {
    exit() {
      message.success(
          '成功退出',
          1,
          (() => {
            this.$router.push({
              path: "/"
            })
          })
      );
    }
  }
});
