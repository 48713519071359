import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = { style: {"width":"300px"} }
const _hoisted_3 = {
  key: 0,
  style: {"width":"650px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_button, { onClick: _ctx.showModal }, {
      default: _withCtx(() => [
        _createTextVNode("aaaaaa")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
      width: "1000px",
      title: "选取数据集",
      onOk: _ctx.handleOk
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_table, {
              columns: _ctx.columns,
              dataSource: _ctx.tableData,
              pagination: false,
              "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
            type: 'radio',
          },
              "show-header": false
            }, null, 8, ["columns", "dataSource", "row-selection"])
          ]),
          (_ctx.showData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_el_text, { style: {"font-size":"larger","font-weight":"bold","margin-left":"20px"} }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.selectedRowName), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table, {
                  data: _ctx.data.dataBase.slice(0, 20),
                  style: {"margin-left":"20px","margin-top":"30px"},
                  "max-height": "600px",
                  "header-cell-style": {background: '#edf6ff', color: '#606266'},
                  "empty-text": "暂无数据"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.dataColumns, (item, i) => {
                      return (_openBlock(), _createBlock(_component_el_table_column, {
                        key: i,
                        property: item,
                        label: item
                      }, null, 8, ["property", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["data"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible", "onOk"])
  ], 64))
}